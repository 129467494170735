import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider, Box } from "@mui/material";
import { theme } from "./helper/Theme";

import "./App.css";

import ScrollToTop from "./helper/ScrollToTop";
import Loader from "./components/Common/Loader/Loader";

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

const Login = React.lazy(() => import('./pages/Login'));
const Signup = React.lazy(() => import('./pages/Signup'));
const PreSale = React.lazy(() => import('./pages/PreSale'));
const Refer = React.lazy(() => import('./pages/Refer'));


const pages = [
  { name: 'PreSale', path: 'pre-sale', element: <PreSale /> },
  { name: 'Refer', path: 'refer', element: <Refer /> },
]

// 1. Get projectId
const projectId = "ab4b25ee70e50f539080a1e765e300b8"

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com'
}

const sepolia = {
  chainId: 11155111,
  name: 'Sepolia',
  currency: 'ETH',
  explorerUrl: '',
  rpcUrl: 'https://sepolia.infura.io/v3/28c21a32da8a43f6ac4ac47e13a465cc'
}

const hardhatTestNet = {
  chainId: 31337,
  name: 'Hardhat',
  currency: 'ETH',
  explorerUrl: '',
  rpcUrl: 'http://127.0.0.1:8545/'
}

// 3. Create a metadata object
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  // enableInjected: true, // true by default
  // enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 31337, // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [hardhatTestNet,sepolia, mainnet],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})


function App() {


  return (
    <>
      <ScrollToTop>
        <ThemeProvider theme={theme}>
          <Box sx={{ bgcolor: 'black.main', color: 'white.main', height: '100%', width: '100vw', minHeight: '100vh', display: 'flex' ,padding:"1rem",paddingTop:"30px",paddingBottom:"30px"}}>
            <Suspense fallback={<Loader />} >
              <Routes>
                <Route path="/affiliate" element={<Signup />} />
                {/* <Route path="/signup" element={<Signup />} /> */}
                {pages?.map((item, i) => {
                  const { name, path, element } = item;
                  return (
                    <Route key={i} exact path={`/${path}`} name={name} element={element} />
                  )
                })}
              </Routes>
            </Suspense>
          </Box>
        </ThemeProvider>
      </ScrollToTop>
    </>
  );
}

export default App;
